import "./mainProducts.scss";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { vmDescription } from "../../../shared/data/data.js";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import services from "../../../Services/services";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import PurchaseItemData from "../../PurchaseItemData/PurchaseItemData";

const VirtualMachine = () => {
  const navigate = useNavigate();
  const [vmName, setVmName] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageName, setSelectedImageName] = useState("");
  const [selectedImageDefaultDisk, setSelectedImageDefaultDisk] = useState("");
  const [duration, setDuration] = useState("");
  const [durationNumber, setDurationNumber] = useState("");
  const [diskSize, setDiskSize] = useState(null);
  const [volume, setVolume] = useState("");
  const [error, setError] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [vmNameError, setVMNameError] = useState(false);
  const [isItemAdded, setIsItemAdded] = useState(false);
  const [instanceTypes, setInstanceTypes] = useState([]);
  const [machineImages, setMachineImages] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedVCPU, setSelectedVCPU] = useState("");
  const [selectedMemory, setSelectedMemory] = useState("");
  const [loading, setLoading] = useState(true);
  const [priceInBirr, setPriceInBirr] = useState();
  const [etbAmount, setETBAmount] = useState(null);

  // side effect that fetches the instance types
  useEffect(() => {
    const cachedInstanceTypes = JSON.parse(
      sessionStorage.getItem("cachedInstanceTypes")
    );
    const cachedMachineImages = JSON.parse(
      sessionStorage.getItem("cachedMachineImages")
    );

    if (cachedInstanceTypes) {
      setInstanceTypes(cachedInstanceTypes);
      setLoading(false);
    } else {
      fetchInstanceType();
    }

    if (cachedMachineImages) {
      setMachineImages(cachedMachineImages);
    } else {
      fetchMachineImages();
    }
  }, []);

  const handleDurationNumChange = (event) => {
    setDurationNumber(event.target.value); // This is duration time in numbers
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value); // this is selected duration period value
  };

  const handleImageChange = (event) => {
    const selectedImageId = event.target.value;
    setSelectedImage(event.target.value);
    const selectedImageIndex = event.target.selectedIndex;
  
    setSelectedImageName(event.target.options[selectedImageIndex].text);
    setSelectedImageDefaultDisk(filterBlockDeviceMappingById(machineImages,event.target.value))

    const selectedImageObj = machineImages.find(
      (image) => image.id === selectedImageId
    );
    if (selectedImageObj) {
      setDiskSize(selectedImageObj.total_size_gb);
    }
  };

  const filterBlockDeviceMappingById=(data, id)=> {
    const item = data.find(entry => entry.id === id);
    return item ? item.block_device_mapping[0].volume_size_gib : [];
  }

  const handleCheckboxClick = (index, instanceType) => {
    setSelectedRowData(instanceType);
  };

  const addToCartHandler = (redirectUser) => {
    if (
      !vmName ||
      selectedRowData === null ||
      !selectedImage ||
      !volume ||
      !durationNumber ||
      !duration
    ) {
      setVmName(true);
      setError(true);
      return;
    } else {
      const price = priceCalculator(
        selectedRowData.vcpus,
        selectedRowData.memory_mb
      );
      const existingItems =
        JSON.parse(localStorage.getItem("purchaseItems")) || [];

      const purchaseItem = {
        instanceName: selectedRowData.name,
        vcpus: selectedRowData.vcpus,
        memory_mb: mbToGBConverter(selectedRowData.memory_mb),
        name: vmName,
        imageId: selectedImage,
        price: price,
        serviceType: "Virtual machine",
        duration: duration === "year" ? durationNumber * 12 : durationNumber,
        diskSize,
        imageType: selectedImageName,
        volume: {
          size: parseInt(volume),
        },
        // duration: duration,
      };
      const updatedItems = [...existingItems, purchaseItem];
      localStorage.setItem("purchaseItems", JSON.stringify(updatedItems));
      window.dispatchEvent(new Event("storage"));
      setError(false);
      setVMNameError(false);
      setIsItemAdded(true);
      setTimeout(() => {
        setIsItemAdded(false);
      }, 2000);
      if (redirectUser) {
        const userIsLoggedIn = sessionStorage.getItem("loggedIn-user");
        if (userIsLoggedIn) {
          navigate("/purchase-confirm");
        } else {
          navigate("/log-in");
        }
      }
    }
  };

  const fetchInstanceType = async () => {
    try {
      const { data } = await services.getInstanceType();
      setInstanceTypes(data);
      setLoading(false);
      sessionStorage.setItem("cachedInstanceTypes", JSON.stringify(data));
    } catch (error) {
      setFetchError(true);
    } finally {
    }
  };

  const fetchMachineImages = async () => {
    try {
      const response = await services.getMachineImages();
      setMachineImages(response);
      sessionStorage.setItem("cachedMachineImages", JSON.stringify(response));
    } catch (error) {
      setFetchError(true);
    }
  };

  const uniquevCPUs = instanceTypes.reduce((acc, current) => {
    const vCPUs = acc.find((item) => item.vcpus === current.vcpus);
    if (!vCPUs) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const sortedUniquevCPUs = uniquevCPUs.sort((a, b) => a.vcpus - b.vcpus);

  const uniqueMemories = instanceTypes.reduce((acc, current) => {
    const memories = acc.find((item) => item.memory_mb === current.memory_mb);
    if (!memories) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const sortedMemories = uniqueMemories.sort(
    (a, b) => a.memory_mb - b.memory_mb
  );

  const mbToGBConverter = (memory_mb) => {
    const memoryInGB = memory_mb / 1024;
    return `${memoryInGB} GB`;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const paginatedInstanceTypes = instanceTypes.slice(
  //   page * rowsPerPage,
  //   page * rowsPerPage + rowsPerPage
  // );

  const handleVCPUChange = (event) => {
    setSelectedVCPU(event.target.value);
  };

  const handleMemoryChange = (event) => {
    setSelectedMemory(event.target.value);
  };

  // This func resets filtered table to default
  const resetFilter = () => {
    setSelectedVCPU("");
    setSelectedMemory("");

    const vCPUSelect = document.getElementById("vCPUSelect");
    if (vCPUSelect) {
      vCPUSelect.value = "";
    }

    const memorySelect = document.getElementById("memorySelect");
    if (memorySelect) {
      memorySelect.value = "";
    }
  };
  // this func returns the instance types which are paginated and filtering logic is included in it.
  const filterAndSliceInstanceTypes = () => {
    const filteredInstanceTypes = instanceTypes.filter((instanceType) => {
      if (selectedVCPU && selectedMemory) {
        return (
          instanceType.vcpus === parseInt(selectedVCPU) &&
          instanceType.memory_mb === parseInt(selectedMemory)
        );
      } else if (selectedVCPU) {
        return instanceType.vcpus === parseInt(selectedVCPU);
      } else if (selectedMemory) {
        return instanceType.memory_mb === parseInt(selectedMemory);
      } else {
        return true;
      }
    });

    const slicedInstanceTypes = filteredInstanceTypes.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    return slicedInstanceTypes;
  };

  // const priceCalculator = (vcpus, memory, volume, duration, durationNumber) => {
    
  //   if (!vcpus || !memory) {
  //     return "-";
  //   }
  
  //   const vcpusPrice = vcpus * 19.16;
  //   const memoryValue = (memory / 1024) * 2.05;
  //   const volumePrice = volume * 0.12;
  
  //   let totalPrice;
  //   if (duration === "year") {
  //     totalPrice = (vcpusPrice + memoryValue + volumePrice) * durationNumber * 12;
  //   } else {
  //     totalPrice = (vcpusPrice + memoryValue + volumePrice) * durationNumber;
  //   }
  
  //   const dollar = {
  //     dollar: totalPrice,
  //   };
  // console.log("dollar",dollar);
  //   // Call the handleConversion function here
  //   const priceInETB = handleConversion(dollar.dollar)
  // //console.log("price in ETB==>",priceInETB);

  //   return `${priceInETB}`;
  // };

  const priceCalculator = (vcpus, memory) => {
    if (!vcpus || !memory) {
      return "-";
    }
    const vcpusPrice = vcpus * 17.2463;

    const memoryValue = (memory / 1024) * 1.8478;

    const volumePrice = volume * 0.1080;
    if (duration === "year") {
      const totalPrice =
        (vcpusPrice + memoryValue + volumePrice) * durationNumber * 12;
      const dollar = {
        dollar: totalPrice,
      };
      const priceInETB = handleConversion(dollar);
      
      return etbAmount;
    } else {
      const totalPrice =
        (vcpusPrice + memoryValue + volumePrice) * durationNumber;
      const dollar = {
        dollar: totalPrice,
      };
      const priceInETB = handleConversion(dollar);
      console.log("Price in ETB=",etbAmount);

      return etbAmount;
    }
  };

  const handleConversion = async (dollar) => {
    try {
      const response = await services.getDollarExchange(dollar);
      setETBAmount(response);
      setError(null);
  
      return response;
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setError(error.message || "Failed to retrieve exchange rate.");
    }
  };

  const calculateDollar = (dollar) => {
    //const response = await services.getDollarExchange(dollar);
    return dollar.dollar *125;
  };

  return (
    <div className="font-Inter w-full p-2 mt-4 md:p-6 text-center flex flex-col gap-2 md:text-left">
      <h1 className="text-4xl text-center text-transparent bg-clip-text xl:w-fit font-bold bg-gradient-to-r from-primary-blue via-button-color to-secondary-blue">
        Virtual Machine
      </h1>
      {fetchError && (
        <p>
          "Oops! Something went wrong while fetching instance types. Please try
          again later."
        </p>
      )}
      <details className="open:bg-white dark:open:bg-gray-100 p-4 open:shadow-lg rounded-md -mt-2">
        <summary className="text-sm leading-6 select-none">What is VM?</summary>
        <p className="mt-3 text-start leading-7 text-slate-500">
          {vmDescription}
        </p>
      </details>
      <div className="flex flex-col gap-4 text-light-black ">
        <div className="border shadow-md flex text-left flex-col p-4 gap-2">
          <h2 className="text-xl font-medium">Product details</h2>
          <div className="flex items-start">
            <h4>
              Product type: <span className="font-medium">Virtual Machine</span>
            </h4>
          </div>
          <div className="flex flex-col gap-2">
            <input
              title="You can name it whatever you want"
              placeholder="VM name"
              type="text"
              name="vmName"
              id="vmName"
              value={vmName}
              onChange={(e) => setVmName(e.target.value)}
              className="border-b w-[13em] outline-none border-gray-400 placeholder:text-gray-400"
            />
            {vmNameError && (
              <p className="bg-red-100 rounded-sm w-fit py-2 px-3 text-sm border-l-4 border-red-500 pl-1 text-red-500">
                Please provide a VM name
              </p>
            )}
          </div>
          <div className="flex gap-3 items-center flex-wrap">
            <h4>VM specifications:</h4>
            <div className="flex items-center gap-2">
              <h4>vCPU</h4>
              <select
                id="vCPUSelect"
                className="w-[8em] p-2 bg-gray-100 rounded-md"
                defaultValue=""
                onChange={handleVCPUChange}
              >
                <option value="" disabled hidden>
                  Select
                </option>
                {sortedUniquevCPUs.map((instancevCPUs, index) => (
                  <option
                    value={instancevCPUs.vcpus}
                    key={instancevCPUs.name + index}
                  >
                    {instancevCPUs.vcpus} vCPU
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center gap-2">
              <h4>Memory</h4>
              {
                <select
                  id="memorySelect"
                  className="w-[8em] p-2 bg-gray-100 rounded-md"
                  defaultValue=""
                  onChange={handleMemoryChange}
                >
                  <option value="" disabled hidden>
                    Select
                  </option>
                  {sortedMemories.map((memory) => (
                    <option value={memory.memory_mb} key={memory.memory_mb}>
                      {mbToGBConverter(memory.memory_mb)}
                    </option>
                  ))}
                </select>
              }
            </div>
            <button
              onClick={resetFilter}
              id="rotateButton"
              className="flex items-center transition-colors duration-500 ease-in-out text-gray-600 hover:bg-gray-200 border border-transparent p-2 rounded-full reset"
            >
              <RestartAltIcon />
            </button>
          </div>

          <TableContainer
            component={Paper}
            style={{
              minHeight: "30em",
              maxWidth: "50em",
            }}
          >
            <Table
              sx={{ minHeight: "26.9em" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow className="bg-gray-200 ">
                  <TableCell></TableCell>
                  <TableCell>Instance name</TableCell>
                  <TableCell align="left">vCPUs</TableCell>
                  <TableCell align="left">Memory</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  filterAndSliceInstanceTypes().map((instanceType, index) => (
                    <TableRow
                      key={instanceType.name + index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          // checked={selectedRow === index}
                          checked={selectedRowData === instanceType} // Check if this row is selected
                          onChange={() =>
                            handleCheckboxClick(index, instanceType)
                          }
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {instanceType.name}
                      </TableCell>
                      <TableCell align="left">
                        <span className="flex items-center">
                          {" "}
                          {`${instanceType.vcpus} vCPU`}
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        {mbToGBConverter(instanceType.memory_mb)}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <TablePagination
              className="bg-gray-200 w-full flex items-center justify-center"
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={instanceTypes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
        <div className="border shadow-md flex text-left flex-col p-4 gap-2">
          <div className="flex flex-col w-ful">
            <h4 className="text-lg mb-2 font-medium ">Image Management</h4>
            <div className="flex items-center gap-2">
              <label>Image:</label>
              <select
                name="image"
                className="w-[12em] p-1.5 bg-gray-100 rounded-md "
                value={selectedImage}
                onChange={handleImageChange}
              >
                <option
                  value=""
                  className="first:text-red-500 "
                  disabled
                  hidden
                >
                  Select image{" "}
                </option>
                {machineImages.map((machineImage, index) => (
                  <option key={machineImage.id + index} value={machineImage.id}>
                    {machineImage.name}
                  </option>
                ))}
              </select>
            </div>
            {selectedImageDefaultDisk && (
  <div className="mt-2 text-sm text-gray-600">
    Default Disk: {selectedImageDefaultDisk} GB
  </div>
)}
          </div>

          <div className="mt-4 flex gap-4 items-center">
            <div className="flex gap-2 items-center">
              <label>Duration:</label>
              <select
                name="duration"
                className="w-[8em] p-1.5 bg-gray-100 rounded-md before:content"
                value={durationNumber}
                onChange={handleDurationNumChange}
              >
                <option value="" disabled hidden>
                  Select duration{" "}
                </option>
                {Array.from({ length: 12 }, (_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={duration}
                onChange={handleDurationChange}
              >
                <button
                  className={`${
                    duration === "month"
                      ? "bg-button-color text-white"
                      : "bg-slate-200 text-gray-500"
                  } p-1 text-sm w-[5em] rounded-l-md md:text-base`}
                  onClick={() => setDuration("month")}
                >
                  Month
                </button>
                <button
                  className={`${
                    duration === "year"
                      ? "bg-button-color text-white"
                      : "bg-slate-200 text-gray-500"
                  } p-1 text-sm w-[5em] rounded-r-md md:text-base border-gray-400 border-s`}
                  onClick={() => setDuration("year")}
                >
                  Year
                </button>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="flex gap-2">
            <label htmlFor="auto_renewal">Auto-Renewal:</label>
            <input
              className="accent-butbg-button-color caret-white"
              type="checkbox"
              name="auto_renewal"
            />
          </div>
        </div>
        <div className="border shadow-md flex text-left flex-col p-4 gap-2">
          <h2 className="text-lg font-medium ">Volume management</h2>
          <div className="flex gap-2">
            <label htmlFor="volume">
              Volume size <span className="text-sm text-gray-500">(in GB)</span>
            </label>
            <input
              title="You can name it whatever you want"
              placeholder="Volume size"
              type="number"
              name="volume"
              id="volume"
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
              className="border-b w-[8em] outline-none border-gray-400  placeholder:text-gray-400"
            />
          </div>
        </div>
      </div>
      <PurchaseItemData className="w-[100px]" >
        {error && (
         <span className="bg-red-100 rounded-sm w-[300px] py-2 px-3 text-sm border-l-4 border-red-500 pl-1 text-red-500">

            Please make sure you have selected all available options*
          </span>
        )}
  
  <div className="flex flex-wrap justify-between gap-4">
  <div className="w-full sm:w-auto">
    <div className="flex flex-wrap gap-2">
      <h3>VM type:</h3>
      <span className="text-black/85 font-medium">
        {selectedRowData?.vcpus ? `${selectedRowData.vcpus} vCPU` : ""}
      </span>
      -
      <span className="text-black/85 font-medium">
        {selectedRowData?.memory_mb
          ? `${mbToGBConverter(selectedRowData.memory_mb)}`
          : ""}
      </span>
    </div>
    <div className="flex flex-wrap gap-2">
      <h3>Selected image:</h3>
      <span className="text-black/85 font-medium">
        {selectedImageName || "-"}
      </span>
    </div>
  </div>

  <div className="w-full sm:w-auto">
    <div className="flex flex-wrap gap-2">
      <h3>Volume size:</h3>
      <span className="text-black/85 font-medium">
        {volume ? `${volume} GB` : "-"}
      </span>
    </div>
    <div className="flex flex-wrap gap-2">
      <h3>Duration:</h3>
      <span className="text-black/85 font-medium">
        {durationNumber || "-"}
      </span>
      <span className="text-black/85 font-medium">
        {duration || "-"}
      </span>
    </div>
  </div>

  <div className="w-full sm:w-auto">
    <div className="flex flex-wrap gap-2">
      <h3>Price:</h3>
      <span className="font-semibold">
        ETB{" "}
        {priceCalculator(selectedRowData?.vcpus, selectedRowData?.memory_mb)}
      </span>
    </div>
  </div>

  <div className="w-full sm:w-auto flex gap-4 items-center">
    <Button
      style={{
        fontSize: "12px",
        backgroundColor: "#BC68B2",
      }}
      className="w-[6em] md:w-[8em]"
      variant="contained"
      onClick={() => addToCartHandler(true)}
    >
      Buy
    </Button>
    <Button
      onClick={() => addToCartHandler(false)}
      style={{
        fontSize: "12px",
        backgroundColor: "white",
        border: "1px solid #BC68B2",
        color: "#BC68B2",
      }}
      variant="contained"
    >
      Add to Cart
    </Button>
  </div>

  <div className="w-full sm:w-auto text-center">
    <div className="background flex justify-center items-center">
      <img
        src={require("../../../shared/images/vmImage2.webp")}
        alt="Your Company Logo"
        className="max-w-full h-auto"
      />
    </div>
  </div>
</div>



     
     
        
      
      </PurchaseItemData>
      {isItemAdded && (
        <div className="fixed inset-0 z-50 flex items-start justify-center bg-black/10">
          <div className="border border-green-500 bg-white p-3 mx-auto rounded-md shadow-md flex items-center justify-center gap-2 mt-8 animate-bounce">
            <CheckCircleIcon className="text-green-600" />
            <p className="text-green-600 text-sm font-medium ">
              Item added to the cart!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VirtualMachine;
